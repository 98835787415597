import React from 'react';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';

const Search = ({ searchTerm, setSearchTerm }) => {
  return (
    <div>
      <InputGroup size="lg">
        <Form.Control
          aria-label="SDS Search"
          placeholder="Product Keyword or UPC code"
          aria-describedby="inputGroup-sizing-sm"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <ButtonGroup size="lg">
          <Button className="btn bg-white clearSearch border-top border-bottom text-danger" onClick={(e) => setSearchTerm('')}>
            <i className="fa-sharp fa-solid fa-xmark" />
          </Button>
          <Button variant="primary">
            <i className="fa-sharp fa-solid fa-magnifying-glass" />
            <span className="d-none d-lg-inline ms-1">Search Keyword</span>
          </Button>
        </ButtonGroup>
      </InputGroup>
    </div>
  );
};

export default Search;
