export function convertStyleStringToObject(styleString) {
  if (!styleString) return {};

  return styleString.split(';').reduce((acc, style) => {
    const [property, value] = style.split(':').map((item) => item.trim());
    if (property && value) {
      // Convert kebab-case to camelCase
      const camelProperty = property.replace(/-([a-z])/g, (g) => g[1].toUpperCase());
      acc[camelProperty] = value;
    }
    return acc;
  }, {});
}
