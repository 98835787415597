import React from 'react';
import Search from './Controls-Search';
import LocCfgScan from './Controls-LocCfgScan';

const Controls = ({ searchTerm, setSearchTerm, selectedLocation, setSelectedLocation, userSettings, setUserSettings }) => {
  return (
    <div className="px-3">
      <Search searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
      <LocCfgScan
        selectedLocation={selectedLocation}
        setSelectedLocation={setSelectedLocation}
        userSettings={userSettings}
        setUserSettings={setUserSettings}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
      />
    </div>
  );
};

export default Controls;
