export const languageConfig = {
  es: { name: 'Español', color: '#28a745' }, // Green
  ko: { name: '한국어', color: '#4B0082' }, // Indigo
  fr: { name: 'Français', color: '#0000FF' }, // Blue
  de: { name: 'Deutsch', color: '#008000' }, // Green
  it: { name: 'Italiano', color: '#FF0000' }, // Red
  ja: { name: '日本語', color: '#800080' }, // Purple
  zh: { name: '中文', color: '#FF1493' }, // Deep Pink
  // Add more languages as needed
};
