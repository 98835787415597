//create the pinned documents list component
import React from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import PinnedDocumentItem from './Pinned-Document-Item';

const PinnedDocumentsList = ({ pinnedDocuments, selectedLocation }) => {
  //filter out pinned documents using the array property locations that don't match the selected location
  const filteredPinnedDocuments = selectedLocation.locationID
    ? pinnedDocuments.filter((pinnedDocument) => pinnedDocument.locations.includes(selectedLocation.locationID))
    : pinnedDocuments;

  return (
    <div className="pinned-documents-list px-lg-3 mb-3">
      <ListGroup>
        {filteredPinnedDocuments.map((pinnedDocument) => {
          return <PinnedDocumentItem key={pinnedDocument._id} pinnedDocument={pinnedDocument} />;
        })}
      </ListGroup>
    </div>
  );
};
//export the component
export default PinnedDocumentsList;
