import React, { useState } from 'react';
import { Collapse } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
const LocationListItem = ({ children, location, selectedLocation, setSelectedLocation }) => {
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);
  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  var squareIcon = <i className="fa-sharp fa-solid fa-square" />;
  if (children) {
    squareIcon = <i onClick={(e) => toggleExpanded()} className="fa-sharp fa-solid fa-square-plus" />;
    if (expanded) {
      squareIcon = <i onClick={(e) => toggleExpanded()} className="fa-sharp fa-solid fa-square-minus" />;
    }
  }

  //create a setHash function that lads the locationID to the URL
  const setHash = (locationID) => {
    //window.location.hash = `#loc:${locationID}`;
    navigate(`/loc/${locationID}`);
  };

  return (
    <div className="locationElement">
      <div className="parentCont d-flex ms-2">
        <span className={'locSquare align-self-center text-' + location.color}>{squareIcon}</span>
        <span onClick={(e) => setHash(location.locationID)} className="locName mx-2 align-self-center flex-grow-1">
          {location.name}
        </span>
      </div>
      <Collapse in={expanded}>
        <div>{children}</div>
      </Collapse>
    </div>
  );
};

export default LocationListItem;
