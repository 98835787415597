import React, { useContext } from 'react';
import { PortalContext } from 'context/Portal';
import { convertStyleStringToObject } from 'utils/customAttributeUtils';

const NumberDisplay = ({ attribute }) => {
  const portalConfigs = useContext(PortalContext);
  const { name, value } = attribute;
  const settings = portalConfigs.portal.attributeSettings.find((setting) => setting.name === name);

  // Convert style strings to objects
  const componentStyle = convertStyleStringToObject(settings.styles?.component);
  const labelStyle = convertStyleStringToObject(settings.styles?.label);
  const valueStyle = convertStyleStringToObject(settings.styles?.value);

  return (
    <div className="numberDisplay" style={componentStyle}>
      <div className="number-label" style={labelStyle}>
        {name}:
      </div>
      <div className="number-value" style={valueStyle}>
        {value}
      </div>
    </div>
  );
};

const NumberEdit = ({ attribute, onChange }) => {
  const { attributeSettings } = useContext(PortalContext);
  const { name, value } = attribute;
  const settings = attributeSettings[name] || {};

  const handleChange = (e) => {
    onChange({ ...attribute, value: e.target.value });
  };

  return (
    <div className="mb-3">
      <label htmlFor={`number-edit-${name}`} className="form-label">
        {name}
      </label>
      <input
        type="number"
        className="form-control"
        id={`number-edit-${name}`}
        value={value}
        onChange={handleChange}
        style={settings.style}
        min={settings.min}
        max={settings.max}
        step={settings.step}
      />
    </div>
  );
};

export { NumberDisplay, NumberEdit };
