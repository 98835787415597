import Keycloak from 'keycloak-js';
//a function that extracts subdomain from the accesed url
let subdomain = window.location.hostname.split('.')[0];
//subdomain = 'acmemfgco';
const keycloakConfig = {
  url: 'https://sds.zone/auth/',
  realm: 'dss-realm',
  clientId: 'portal-app',
};
const kc = new Keycloak(keycloakConfig);

export default kc;
