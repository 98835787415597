import React, { useCallback, useState } from 'react';
import { Image as BootstrapImage, Badge, Button } from 'react-bootstrap';
import { useModal } from 'context/ModalContext';

const preprocessImageUrl = (photoURL, isThumbnail = false) => {
  const subdomain = window.location.hostname.split('.')[0];
  const baseUrl = process.env.REACT_APP_API_URL;
  const resourcePath = isThumbnail ? 'thumbnail' : 'resources';
  return process.env.REACT_APP_DEV === 'true' ? `${baseUrl}/${resourcePath}/${subdomain}/${photoURL}` : `${baseUrl}/${resourcePath}/${photoURL}`;
};

const LoadingIndicator = () => (
  <div className="position-fixed top-50 start-50 translate-middle">
    <i className="fas fa-cog fa-spin fa-3x"></i>
  </div>
);

const Images = ({ urls }) => {
  const { showModal, updateModal } = useModal();
  const [isLoading, setIsLoading] = useState(false);

  const createModalId = useCallback((index) => {
    return `image-modal-${index}-${Date.now()}`;
  }, []);

  const preloadImage = (url) => {
    return new Promise((resolve, reject) => {
      const img = new window.Image();
      img.onload = () => resolve(img);
      img.onerror = reject;
      img.src = url;
    });
  };

  const handleShow = useCallback(
    async (index) => {
      setIsLoading(true);
      try {
        await preloadImage(preprocessImageUrl(urls[index]));
        const modalId = createModalId(index);
        showModal({
          id: modalId,
          title: 'Product Image',
          draggable: true,
          resizable: false,
          overlay: false,
          children: renderModalContent(index, modalId),
        });
      } finally {
        setIsLoading(false);
      }
    },
    [showModal, urls, createModalId]
  );

  const handlePrev = useCallback(
    async (index, modalId) => {
      const prevIndex = index === 0 ? urls.length - 1 : index - 1;
      updateModal(modalId, { children: <LoadingIndicator /> });
      await preloadImage(preprocessImageUrl(urls[prevIndex]));
      updateModal(modalId, {
        children: renderModalContent(prevIndex, modalId),
      });
    },
    [urls, updateModal]
  );

  const handleNext = useCallback(
    async (index, modalId) => {
      const nextIndex = index === urls.length - 1 ? 0 : index + 1;
      updateModal(modalId, { children: <LoadingIndicator /> });
      await preloadImage(preprocessImageUrl(urls[nextIndex]));
      updateModal(modalId, {
        children: renderModalContent(nextIndex, modalId),
      });
    },
    [urls, updateModal]
  );

  const renderModalContent = useCallback(
    (index, modalId) => (
      <div className="d-flex justify-content-center align-items-center">
        {urls.length > 1 && (
          <Button variant="link" onClick={() => handlePrev(index, modalId)} className="me-3">
            <i className="fa-sharp fa-solid fa-arrow-left"></i>
          </Button>
        )}
        <BootstrapImage
          src={preprocessImageUrl(urls[index])}
          style={{
            maxHeight: '75vh',
            maxWidth: '75vw',
            objectFit: 'contain',
          }}
        />
        {urls.length > 1 && (
          <Button variant="link" onClick={() => handleNext(index, modalId)} className="ms-3">
            <i className="fa-sharp fa-solid fa-arrow-right"></i>
          </Button>
        )}
      </div>
    ),
    [urls, handlePrev, handleNext]
  );

  if (!urls || urls.length === 0) {
    return null;
  }

  return (
    <div>
      <div className="position-relative d-inline-block">
        <BootstrapImage src={preprocessImageUrl(urls[0], true)} thumbnail onClick={() => handleShow(0)} style={{ cursor: 'pointer' }} />
        {urls.length > 1 && (
          <Badge pill bg="primary" className="position-absolute top-0 end-0">
            {urls.length}
          </Badge>
        )}
      </div>
      {isLoading && <LoadingIndicator />}
    </div>
  );
};

export default Images;
