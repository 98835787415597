import React, { useState, useEffect } from 'react';
import { Button, Form, Badge } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';

const LifecycleBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [counts, setCounts] = useState({ pending: 0, inProcess: 0, active: 0, archived: 0 });

  useEffect(() => {
    // Fetch counts from your API here
    // For example:
    // fetchCounts().then(data => setCounts(data));
  }, []);

  const handleButtonClick = (path) => {
    navigate(`/admin/${path}`);
  };

  return (
    <div className="lifecycle-bar mt-5 p-2 bg-light">
      <Button
        variant={location.pathname === '/admin/pending' ? 'primary' : 'outline-primary'}
        className="mr-2 rounded-0"
        onClick={() => handleButtonClick('pending')}
      >
        Pending{' '}
        <Badge bg="light" text="dark">
          {counts.pending}
        </Badge>
      </Button>
      <Button
        variant={location.pathname === '/admin/in-process' ? 'secondary' : 'outline-secondary'}
        className="mr-2 rounded-0"
        onClick={() => handleButtonClick('in-process')}
      >
        In Process{' '}
        <Badge bg="light" text="dark">
          {counts.inProcess}
        </Badge>
      </Button>
      <Button
        variant={location.pathname === '/admin/active' || location.pathname === '/admin' ? 'success' : 'outline-success'}
        className="mr-2 rounded-0"
        onClick={() => handleButtonClick('active')}
      >
        Active{' '}
        <Badge bg="light" text="dark">
          {counts.active}
        </Badge>
      </Button>
      <Button
        variant={location.pathname === '/admin/archived' ? 'info' : 'outline-info'}
        className="mr-2 rounded-0"
        onClick={() => handleButtonClick('archived')}
      >
        Archived{' '}
        <Badge bg="light" text="dark">
          {counts.archived}
        </Badge>
      </Button>
      <Form.Control type="text" placeholder="Search" className="d-inline-block w-auto ml-2 rounded-0" />
    </div>
  );
};

export default LifecycleBar;
