import React, { useState, useEffect, useCallback } from 'react';
import Button from 'react-bootstrap/Button';
import Collapse from 'react-bootstrap/Collapse';
import Locations from './Controls-Locations';
import Settings from './Controls-Settings';
import QuaggaScan from './quagga/QuaggaScan';

const LocCfgScan = ({ selectedLocation, setSelectedLocation, userSettings, setUserSettings, searchTerm, setSearchTerm }) => {
  const [locationsOpen, setLocationsOpen] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [scannerOpen, setScannerOpen] = useState(false);

  const toggleLocations = useCallback(() => setLocationsOpen((prev) => !prev), []);
  const toggleSettings = useCallback(() => setSettingsOpen((prev) => !prev), []);
  const openScanner = useCallback(() => setScannerOpen(true), []);

  useEffect(() => {
    setLocationsOpen(false);
  }, [selectedLocation]);

  const locationCaret = locationsOpen ? <i className="fa-sharp fa-solid fa-caret-up" /> : <i className="fa-sharp fa-solid fa-caret-down" />;

  const locationButtonProps = {
    onClick: toggleLocations,
    size: 'lg',
    className: `btn btn-lg ${
      selectedLocation?.color ? `btn-${selectedLocation.color}` : 'btn-dark'
    } flex-fill d-flex justify-content-between align-items-center locationButton`,
  };

  return (
    <>
      <div className="w-100 mt-4">
        <div className="d-flex">
          <Button {...locationButtonProps}>
            {selectedLocation?.name || 'All Documents'}
            {locationCaret}
          </Button>
          <Button
            onClick={toggleSettings}
            size="lg"
            variant="light"
            className={`mx-3 d-flex align-items-center openSettings ${settingsOpen ? 'open' : ''}`}
          >
            <i className="fa-sharp fa-solid fa-gear" />
          </Button>
          <Button onClick={openScanner} size="lg" variant="dark">
            <i className="fa-sharp fa-solid fa-camera" />
            <span className="d-none d-lg-inline ms-2">Scan UPC</span>
          </Button>
        </div>
        <Collapse in={settingsOpen}>
          <div>
            <Settings userSettings={userSettings} setUserSettings={setUserSettings} />
          </div>
        </Collapse>
        <Collapse in={locationsOpen}>
          <div>
            <Locations selectedLocation={selectedLocation} setSelectedLocation={setSelectedLocation} />
          </div>
        </Collapse>
        <div className="d-grid my-2">
          <Button size="lg" variant="success" className="btn-block">
            Request Missing SDS
          </Button>
        </div>
      </div>
      {scannerOpen && (
        <ScannerOverlay
          setScannerOpen={setScannerOpen}
          setUserSettings={setUserSettings}
          userSettings={userSettings}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
        />
      )}
    </>
  );
};

const ScannerOverlay = ({ setScannerOpen, setUserSettings, userSettings, searchTerm, setSearchTerm }) => (
  <div
    className="fullscreen-div"
    style={{
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      zIndex: 10000,
      height: '100%',
      width: '100vw',
      background: '#000000',
    }}
  >
    <React.Suspense
      fallback={
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <h1 className="text-light">
            <i className="fa-duotone fa-loader fa-spin-pulse fa-2xl" />
          </h1>
          <br />
          <p style={{ color: '#fff' }}>Loading Document</p>
        </div>
      }
    >
      <QuaggaScan
        setScannerOpen={setScannerOpen}
        setUserSettings={setUserSettings}
        userSettings={userSettings}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
      />
    </React.Suspense>
  </div>
);

export default LocCfgScan;
