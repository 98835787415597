import { useEffect, useState } from 'react';
import axios from 'axios';
import { useKeycloak } from '@react-keycloak/web';

export const useGet = () => {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const { keycloak, initialized } = useKeycloak();

  const getData = async (path, options) => {
    var apiURLPrefix = '/api';
    if (process.env.NODE_ENV !== 'production') {
      apiURLPrefix = 'http://api.dss2.center';
    }

    try {
      const headers = {
        subdomain: window.location.hostname.split('.')[0],
      };

      if (keycloak.authenticated) {
        headers.Authorization = `Bearer ${keycloak.token}`;
      }

      //combine the headers object and the options object into a single object
      options = { ...options, headers };

      const response = await axios.get(`${apiURLPrefix}${path}`, options);
      setData(response.data);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getDocument = async (pdfRendered) => {
    //get the document from remote server or local storage
    let documentURL;
    let subdomain = window.location.hostname.split('.')[0];
    if (pdfRendered.fileLocation === undefined) {
      documentURL = `/sds/${pdfRendered.documentID}`;
    } else {
      documentURL = `/documents/${pdfRendered.fileLocation}`;
    }
    getData(documentURL, { responseType: 'arraybuffer' });
  };

  const getImage = async (urlFragment) => {
    //get the document from remote server or local storage
    let imageURL;
    let subdomain = window.location.hostname.split('.')[0];
    imageURL = `/resources/${subdomain}/${urlFragment}`;
    getData(imageURL);
  };

  return { getDocument, getImage, getData, data, isLoading, error };
};
