import React, { useContext } from 'react';
import { PortalContext } from 'context/Portal';
import { convertStyleStringToObject } from 'utils/customAttributeUtils';

const StringDisplay = ({ attribute }) => {
  const portalConfigs = useContext(PortalContext);
  const { name, value } = attribute;
  //the settings are in the portal.attributeSettings array. Lookup the object that has the same name property
  const settings = portalConfigs.portal.attributeSettings.find((setting) => setting.name === name);

  // Convert style strings to objects
  const componentStyle = convertStyleStringToObject(settings.styles?.component);
  const labelStyle = convertStyleStringToObject(settings.styles?.label);
  const valueStyle = convertStyleStringToObject(settings.styles?.value);

  return (
    <div className="stringDisplay" style={componentStyle}>
      <div className="string-label" style={labelStyle}>
        {name}
      </div>
      <div className="string-value" style={valueStyle}>
        {value}
      </div>
    </div>
  );
};

const StringEdit = ({ attribute, onChange }) => {
  const { name, value } = attribute;

  const handleChange = (e) => {
    onChange({ ...attribute, value: e.target.value });
  };

  return (
    <div className="mb-3">
      <label htmlFor={`string-edit-${name}`} className="form-label">
        {name}
      </label>
      <input type="text" className="form-control" id={`string-edit-${name}`} value={value} onChange={handleChange} />
    </div>
  );
};

export { StringDisplay, StringEdit };
