import React, { useState, useRef, useEffect, useCallback, lazy } from 'react';
//import Quagga from '@ericblade/quagga2';
import './quaggaStyle.css';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { getVideoDevices } from 'utils';
const Quagga = lazy(() => import('@ericblade/quagga2'));
const Scanner = lazy(() => import('./Scanner'));
//import Scanner from './Scanner';
//import Result from './Result';

const QuaggaScan = ({ userSettings, setUserSettings, setScannerOpen, setSearchTerm }) => {
  const [scanning, setScanning] = useState(true); // toggleable state for "should render scanner"
  const [cameras, setCameras] = useState([]); // array of available cameras, as returned by Quagga.CameraAccess.enumerateVideoDevices()
  const [cameraId, setCameraId] = useState(null); // id of the active camera device
  const [cameraError, setCameraError] = useState(null); // error message from failing to access the camera
  const [results, setResults] = useState([]); // list of scanned results
  const scannerRef = useRef(null); // reference to the scanner element in the DOM
  const [windowWidth, setWindowWidth] = useState(window.innerWidth); // window width
  const [windowHeight, setWindowHeight] = useState(window.innerHeight); // window height

  //when this component is rendered add an overflow:hidden tag to the body to prevent scrolling
  useEffect(() => {
    //document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  const closeScanner = () => {
    setScannerOpen(false);
  };

  const handleResults = (result) => {
    console.log('Result:', result);
    setResults([...results, result]);
    setSearchTerm(result);
    setScanning(false);
    setScannerOpen(false);
  };

  const requestCameraPermissions = async () => {
    if (userSettings.devices.length === 0) {
      //get the video devices using getVideoDevices
      try {
        const devices = await getVideoDevices();
        console.log('Devices:', devices);
        if (devices.rearCameras.length > 0) {
          const rearCameras = devices.rearCameras.map((device) => ({
            deviceId: device.deviceId,
            label: device.label,
          }));
          setUserSettings({ ...userSettings, devices: devices.drearCameras });
          if (devices.defaultCamera) {
            setUserSettings({ ...userSettings, camera: devices.defaultCamera.deviceId });
          }
        }
      } catch (error) {
        console.error('Unable to get Devices Error:', error);
        setUserSettings({ ...userSettings, devices: 'denied' });
      }
    }
  };

  if (!userSettings.camera) {
    requestCameraPermissions();
    return <div style={{ color: 'white' }}>Requesting Camera Permissions</div>;
  } else {
    console.log('Initialing Quagga Scanner...', userSettings.camera);
    return (
      <div className="d-flex flex-column d-flex h-100" id="quaggaWindow">
        <div className="flex-grow-1" style={{ position: 'relative' }} ref={scannerRef}>
          {/* <video style={{ width: window.innerWidth, height: 480, border: '3px solid orange' }}/> */}
          <canvas
            className="drawingBuffer"
            style={{
              position: 'absolute',
              top: '0px',
              left: '0px',
              height: '100%',
              width: '100%',
              border: '3px solid green',
            }}
            width={windowWidth}
            height={windowHeight}
          />
          {scanning ? (
            <Scanner
              setScannerOpen={setScannerOpen}
              userSettings={userSettings}
              setUserSettings={setUserSettings}
              scannerRef={scannerRef}
              cameraId={userSettings.camera}
              onDetected={(result) => handleResults(result)}
            />
          ) : null}
        </div>
        <div className="position-absolute bottom-0 start-0 end-0">
          <ButtonGroup size="lg" className="w-100" aria-label="Scanning Controls">
            <Button className="rounded-0" variant="primary">
              Left
            </Button>
            <Button onClick={closeScanner} className="rounded-0" variant="danger">
              Close Scanner
            </Button>
          </ButtonGroup>
        </div>
      </div>
    );
  }
};

export default QuaggaScan;
