const subdomain = window.location.hostname.split('.')[0];
const backCameraKeywords = [
  'rear',
  'back',
  'rück',
  'arrière',
  'trasera',
  'trás',
  'traseira',
  'posteriore',
  '后面',
  '後面',
  '背面',
  '后置', // alternative
  '後置', // alternative
  '背置', // alternative
  'задней',
  'الخلفية',
  '후',
  'arka',
  'achterzijde',
  'หลัง',
  'baksidan',
  'bagside',
  'sau',
  'bak',
  'tylny',
  'takakamera',
  'belakang',
  'אחורית',
  'πίσω',
  'spate',
  'hátsó',
  'zadní',
  'darrere',
  'zadná',
  'задня',
  'stražnja',
  'belakang',
  'बैक',
];

const getVideoDevices = () => {
  return new Promise(async (resolve, reject) => {
    console.log('Requesting Permissions if needed');
    //use the getUserMedia api to request permissions
    const constraints = {
      video: true,
      audio: false,
    };
    navigator.mediaDevices
      .getUserMedia(constraints)
      .then(async (stream) => {
        console.log('Got MediaStream:', stream);
        //close the open stream
        stream.getTracks().forEach((track) => track.stop());
        let rearCameras = [];
        console.log('Enumerating video devices...');
        const devices = await navigator.mediaDevices.enumerateDevices();
        console.log('Devices:', devices);
        const videoDevices = devices.filter((device) => device.kind === 'videoinput');
        console.log('Video Devices:', videoDevices);
        const availableDevices = videoDevices.map((device) => ({
          deviceId: device.deviceId,
          label: device.label,
        }));

        availableDevices.forEach((device) => {
          backCameraKeywords.forEach((keyword) => {
            if (device.label.toLowerCase().includes(keyword)) {
              rearCameras.push(device);
            }
          });
        });
        //if rearCameras is empty, return all video devices
        if (rearCameras.length === 0) {
          rearCameras = availableDevices;
        }
        const cameraResults = {
          rearCameras,
          defaultCamera: rearCameras[rearCameras.length - 1],
        };
        resolve(cameraResults);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export { subdomain, getVideoDevices };
