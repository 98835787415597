import React, { createContext, useState, useContext, useCallback, useMemo } from 'react';
import PowerModal from '../components/admin/sub-components/PowerModal';

const ModalContext = createContext();

export const useModal = () => useContext(ModalContext);

export const ModalProvider = ({ children }) => {
  const [modals, setModals] = useState([]);

  const showModal = useCallback((modalProps) => {
    setModals((prevModals) => {
      const existingModalIndex = prevModals.findIndex((modal) => modal.id === modalProps.id);
      const maxZIndex = prevModals.length > 0 ? Math.max(...prevModals.map((modal) => modal.zIndex)) : 0;
      const newModal = { ...modalProps, show: true, zIndex: maxZIndex + 1, modalId: `modal-${Date.now()}` };

      if (existingModalIndex !== -1) {
        // If modal with this ID already exists, update it
        const updatedModals = [...prevModals];
        updatedModals[existingModalIndex] = newModal;
        return updatedModals;
      } else {
        // If it's a new modal, add it to the array
        return [...prevModals, newModal];
      }
    });
  }, []);

  const hideModal = useCallback((id) => {
    setModals((prevModals) => prevModals.filter((modal) => modal.id !== id));
  }, []);

  const updateModal = useCallback((id, newProps) => {
    setModals((prevModals) => prevModals.map((modal) => (modal.id === id ? { ...modal, ...newProps } : modal)));
  }, []);

  const bringToFront = useCallback((id) => {
    setModals((prevModals) => {
      const maxZIndex = prevModals.length > 0 ? Math.max(...prevModals.map((modal) => modal.zIndex)) : 0;
      return prevModals.map((modal) => (modal.id === id ? { ...modal, zIndex: maxZIndex + 1 } : modal));
    });
  }, []);

  const contextValue = useMemo(
    () => ({
      showModal,
      hideModal,
      updateModal,
      bringToFront,
    }),
    [showModal, hideModal, updateModal, bringToFront]
  );

  return (
    <ModalContext.Provider value={contextValue}>
      {children}
      {modals.map((modal) => (
        <PowerModal key={modal.id} {...modal} onHide={() => hideModal(modal.id)} onClick={() => bringToFront(modal.id)} />
      ))}
    </ModalContext.Provider>
  );
};
