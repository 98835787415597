import React, { useMemo } from 'react';
import { useReactTable, getCoreRowModel, flexRender, createColumnHelper } from '@tanstack/react-table';

const columnHelper = createColumnHelper();

const columns = [
  columnHelper.accessor('chemical', {
    header: 'Chemical',
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('manufacturer', {
    header: 'Manufacturer',
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('signalWord', {
    header: 'Signal Word',
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('pictograms', {
    header: 'Pictograms',
    cell: (info) => info.getValue().map((pic) => <img key={pic} src={pic} alt={pic} className="w-6 h-6 inline-block mr-1" />),
  }),
  columnHelper.accessor('locations', {
    header: 'Locations',
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('sds', {
    header: 'SDS',
    cell: (info) => (
      <a href={info.getValue()} target="_blank" rel="noopener noreferrer">
        View SDS
      </a>
    ),
  }),
  columnHelper.accessor('actions', {
    header: 'Actions',
    cell: (info) => (
      <div>
        <button className="mr-2">Edit</button>
        <button>Delete</button>
      </div>
    ),
  }),
];

const ArchivedTable = ({ data }) => {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <table className="min-w-full divide-y divide-gray-200">
      <thead className="bg-gray-50">
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <th key={header.id} scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody className="bg-white divide-y divide-gray-200">
        {table.getRowModel().rows.map((row) => (
          <tr key={row.id}>
            {row.getVisibleCells().map((cell) => (
              <td key={cell.id} className="px-6 py-4 whitespace-nowrap">
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default ArchivedTable;
