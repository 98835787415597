import React, { useState } from 'react';
import { useReactTable, getCoreRowModel, getPaginationRowModel, flexRender, createColumnHelper } from '@tanstack/react-table';
import Pictogram from '../../Pictograms';
import { Table, Button } from 'react-bootstrap';
import PaginationComponent from '../sub-components/Pagination';
import Images from './sub-components/Images';

const columnHelper = createColumnHelper();

const columns = [
  columnHelper.display({
    id: 'select',
    header: ({ table }) => <input type="checkbox" checked={table.getIsAllRowsSelected()} onChange={table.getToggleAllRowsSelectedHandler()} />,
    cell: ({ row }) => <input type="checkbox" checked={row.getIsSelected()} onChange={row.getToggleSelectedHandler()} />,
    enableSorting: false,
    enableHiding: false,
  }),
  columnHelper.accessor('recordId', {
    header: 'SDS ID',
    cell: (info) => info.getValue(),
  }),

  columnHelper.accessor('photos', {
    header: 'Images',
    cell: (info) => <Images urls={info.getValue()} />,
  }),

  columnHelper.accessor('productIdentifier', {
    header: 'Chemical Name',
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('manName', {
    header: 'Manufacturer',
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('signalWord', {
    header: 'Signal Word',
    cell: (info) => <span className={`badge ${info.getValue() === 'Danger' ? 'bg-danger' : 'bg-warning'}`}>{info.getValue()}</span>,
  }),
  columnHelper.accessor('pictograms', {
    header: 'Pictograms',
    cell: (info) => (
      <div className="d-flex">
        {info.getValue().map((ghs) => (
          <div key={ghs} className="me-1">
            <Pictogram ghs={ghs} />
          </div>
        ))}
      </div>
    ),
  }),
  columnHelper.accessor('locations', {
    header: 'Locations',
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('sds', {
    header: 'SDS',
    cell: (info) => (
      <a href={info.getValue()} target="_blank" rel="noopener noreferrer" className="btn btn-sm btn-primary">
        View SDS
      </a>
    ),
  }),
  columnHelper.accessor('actions', {
    header: 'Actions',
    cell: (info) => (
      <div>
        <Button variant="outline-secondary" size="sm" className="me-2">
          Edit
        </Button>
        <Button variant="outline-danger" size="sm">
          Delete
        </Button>
      </div>
    ),
  }),
];

const ActiveTable = ({ data }) => {
  const [rowSelection, setRowSelection] = useState({});
  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const pagination = {
    pageIndex,
    pageSize,
  };

  const handlePageSizeChange = (newPageSize) => {
    setPagination({
      pageIndex: 0, // Reset to the first page when page size changes
      pageSize: newPageSize,
    });
  };

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    state: {
      rowSelection,
      pagination,
    },
    onRowSelectionChange: setRowSelection,
    onPaginationChange: setPagination,
    enableRowSelection: true,
    pageCount: Math.ceil(data.length / pageSize),
  });

  return (
    <div className="table-container">
      <Table striped bordered hover responsive>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id} className="align-middle">
                  {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id} className="align-middle">
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
      <div className="sticky-pagination">
        <PaginationComponent
          currentPage={table.getState().pagination.pageIndex + 1}
          totalPages={table.getPageCount()}
          pageSize={table.getState().pagination.pageSize}
          totalEntries={data.length}
          onPageChange={table.setPageIndex}
          previousPage={table.previousPage}
          nextPage={table.nextPage}
          onPageSizeChange={handlePageSizeChange} // Pass the page size change handler
        />
      </div>
    </div>
  );
};

export default ActiveTable;
