import React, { createContext, useState, useEffect, useCallback, useContext } from 'react';
import axios from 'axios';
import Portal from '../components/portal/Portal';
import { useKeycloak } from '@react-keycloak/web';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Admin from '../components/admin/Admin';
import Col from 'react-bootstrap/Col';
import { useGet } from 'hooks/useGet';
import { ModalProvider } from './ModalContext';

const PortalContext = createContext();
const PortalProvider = ({ children }) => {
  const [portalConfig, setPortalConfig] = useState(null);
  const [lightbox, setLightbox] = useState(null);
  const { keycloak, initialized } = useKeycloak();
  const portalConfigRequest = useGet();

  //monitor the pinnedDocRequest.data state, and set the pinnedDocuments state when there is data
  useEffect(() => {
    if (portalConfigRequest.data) {
      setPortalConfig(portalConfigRequest.data);
    }
  }, [portalConfigRequest.data]);

  //when the component loads, get the pinned documents using the pinnedDocRequest.getData function
  useEffect(() => {
    portalConfigRequest.getData('/portal/config');
  }, []);

  //if the portalConfig has been successfully loaded return the provider, otherwise return a loading div
  if (!portalConfig?.portal) {
    return <div>Preparing the SDS Portal</div>;
  }
  portalConfig.lightbox = lightbox;
  portalConfig.setLightbox = setLightbox;

  if (portalConfig.portal.accessControl === 'auth') {
    if (keycloak.authenticated) {
      //return the user to the keycloak login ore redirect to the appropriate domain if their tokenParsed.authorized-clients array does not include the subdomain
      if (!keycloak.tokenParsed.authorized_clients.includes(window.location.hostname.split('.')[0])) {
        console.log('User is not authorized for', window.location.hostname.split('.')[0]);
        console.log('User is authorized for', keycloak.tokenParsed.authorized_clients);
        //if user is authorized for at least one client, then redirect to the first client
        if (keycloak.tokenParsed.authorized_clients.length > 0) {
          //set a variable to the REDIRECT_SUFFIX enviroment variable
          window.location.href = `https://${keycloak.tokenParsed.authorized_clients[0]}${process.env.REACT_APP_REDIRECT_SUFFIX}`;
          return false;
        } else {
          //log out the user and return them to the login
          keycloak.logout();
          keycloak.login({ idpHint: portalConfig.portal.idpHint, redirectUri: window.location.href });
          return false;
        }
      }
      console.log('User is Authenticated for', window.location.hostname.split('.')[0]);
      return (
        <PortalContext.Provider value={portalConfig}>
          <BrowserRouter>
            <Routes>
              <Route exact path="/*" element={<Portal />} />
              <Route
                path="/admin/*"
                element={
                  <PrivateRoute>
                    <ModalProvider>
                      <Admin />
                    </ModalProvider>
                  </PrivateRoute>
                }
              />
              <Route element={<Col />} />
            </Routes>
          </BrowserRouter>
        </PortalContext.Provider>
      );
    } else {
      keycloak.login({ idpHint: portalConfig.portal.idpHint, redirectUri: window.location.href });
    }
  } else {
    return (
      <PortalContext.Provider value={portalConfig}>
        <BrowserRouter>
          <Routes>
            <Route exact path="/*" element={<Portal />} />
            <Route
              path="/admin/*"
              element={
                <PrivateRoute>
                  <ModalProvider>
                    <Admin />
                  </ModalProvider>
                </PrivateRoute>
              }
            />
            <Route element={<Col />} />
          </Routes>
        </BrowserRouter>
      </PortalContext.Provider>
    );
  }
};

const PrivateRoute = ({ children }) => {
  const { keycloak } = useKeycloak();
  const portalConfig = useContext(PortalContext);

  if (!keycloak.authenticated) {
    const idpHint = portalConfig?.portal?.idpHint || '';
    keycloak.login({ idpHint, redirectUri: window.location.href });
    return null;
  }
  console.log('Token parsed:', keycloak.tokenParsed);
  const currentSubdomain = window.location.hostname.split('.')[0];
  const authorizedClients = keycloak.tokenParsed?.authorized_clients || [];
  const userRoles = keycloak.tokenParsed?.realm_access?.roles || [];

  if (!authorizedClients.includes(currentSubdomain)) {
    console.log('User is not authorized for', currentSubdomain);
    console.log('User is authorized for', authorizedClients);
    return <Navigate to="/" replace />;
  }

  if (!userRoles.includes('admin')) {
    console.log('User does not have admin role');
    return <Navigate to="/" replace />;
  }

  return children;
};

export { PortalContext, PortalProvider };
