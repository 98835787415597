import React, { useRef, useEffect, useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import Draggable from 'react-draggable';
import './PowerModal.css';

const DraggableModalDialog = ({ children, ...props }) => {
  const nodeRef = useRef(null);
  const [position, setPosition] = useState(null);
  const hasBeenCentered = useRef(false);

  const centerModal = useCallback(() => {
    if (hasBeenCentered.current) return;

    const modalDialog = nodeRef.current;
    if (modalDialog) {
      const modalWidth = modalDialog.offsetWidth;
      const modalHeight = modalDialog.offsetHeight;
      const centerX = (window.innerWidth - modalWidth) / 2;
      const centerY = (window.innerHeight - modalHeight) / 2;

      setPosition({ x: centerX, y: centerY });
      hasBeenCentered.current = true;
    }
  }, []);

  useEffect(() => {
    const timer = setTimeout(centerModal, 0);
    return () => clearTimeout(timer);
  }, [centerModal]);

  const { contentClassName, ...restProps } = props;

  return (
    <Draggable handle=".modal-header" nodeRef={nodeRef} position={position} onStop={(e, data) => setPosition({ x: data.x, y: data.y })}>
      <div ref={nodeRef} className={`modal-dialog ${contentClassName || ''}`} {...restProps} style={{ zIndex: props.zIndex }} onClick={props.onClick}>
        {children}
      </div>
    </Draggable>
  );
};

const PowerModal = ({ show, onHide, title, footerContent, size, children, draggable, resizable, overlay, onClick, zIndex }) => {
  const modalContent = useMemo(
    () => (
      <Modal
        show={show}
        onHide={onHide}
        size={size}
        backdrop={overlay ? true : false}
        dialogAs={draggable ? DraggableModalDialog : undefined}
        dialogClassName={`clickable-modal ${resizable ? 'resizable-modal' : ''} ${size}`}
        onClick={onClick}
        style={{ zIndex }} // Apply zIndex here
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <img src="@datasheetsolutions_small.png" alt="icon" style={{ display: 'inline-block', marginRight: '8px' }} />
            <span>{title}</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{children}</Modal.Body>
        {footerContent && <Modal.Footer>{footerContent}</Modal.Footer>}
      </Modal>
    ),
    [show, onHide, title, footerContent, size, children, draggable, resizable, overlay, onClick, zIndex]
  );

  return modalContent;
};

PowerModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  title: PropTypes.string,
  footerContent: PropTypes.node,
  size: PropTypes.oneOf(['sm', 'lg', 'xl']),
  children: PropTypes.node.isRequired,
  draggable: PropTypes.bool,
  resizable: PropTypes.bool,
  overlay: PropTypes.bool,
  onClick: PropTypes.func,
  zIndex: PropTypes.number,
};

PowerModal.defaultProps = {
  title: '',
  footerContent: null,
  size: '',
  draggable: true,
  resizable: false,
  overlay: true,
  onClick: () => {},
  zIndex: 1,
};

export default PowerModal;
