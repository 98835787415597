import React from 'react';

const Pictogram = ({ ghs }) => {
  const lowerCase = ghs.toLowerCase();
  return (
    <span className="fa-stack fa-3x" style={{ width: '1em', height: '1em', lineHeight: '1em' }}>
      <i
        className="fa-kit-duotone fa-pictogram-border"
        style={{ '--fa-primary-color': '#ffffff', '--fa-secondary-color': '#ff0000', '--fa-secondary-opacity': '1' }}
      />
      <i className={`fa-kit fa-${lowerCase}`} style={{ color: '#000000', position: 'absolute', left: 0 }} />
    </span>
  );
};

export default Pictogram;
