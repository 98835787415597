import React, { useContext } from 'react';
import { PortalContext } from 'context/Portal';
import { convertStyleStringToObject } from 'utils/customAttributeUtils';
import { ListGroup, Form } from 'react-bootstrap';

const MultiSelectDisplay = ({ attribute }) => {
  const portalConfigs = useContext(PortalContext);
  const { name, value } = attribute;
  const settings = portalConfigs.portal.attributeSettings.find((setting) => setting.name === name);

  // Convert style strings to objects
  const componentStyle = convertStyleStringToObject(settings.styles?.component);
  const labelStyle = convertStyleStringToObject(settings.styles?.label);
  const valueStyle = convertStyleStringToObject(settings.styles?.value);

  return (
    <div className="multiSelectDisplay" style={componentStyle}>
      <div className="multiSelect-label" style={labelStyle}>
        {name}:
      </div>
      <ListGroup className="multiSelect-value" style={valueStyle}>
        {Array.isArray(value) ? (
          value.map((item, index) => <ListGroup.Item key={index}>{item}</ListGroup.Item>)
        ) : (
          <ListGroup.Item>{value}</ListGroup.Item>
        )}
      </ListGroup>
    </div>
  );
};

const MultiSelectEdit = ({ attribute, onChange }) => {
  const portalConfigs = useContext(PortalContext);
  const { name, value } = attribute;
  const settings = portalConfigs.portal.attributeSettings.find((setting) => setting.name === name);

  const handleChange = (e) => {
    const selectedOptions = Array.from(e.target.selectedOptions, (option) => option.value);
    onChange({ ...attribute, value: selectedOptions });
  };

  return (
    <div className="mb-3">
      <Form.Label htmlFor={`multiSelect-edit-${name}`}>{name}</Form.Label>
      <Form.Select id={`multiSelect-edit-${name}`} multiple value={Array.isArray(value) ? value : [value]} onChange={handleChange}>
        {settings.options.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
      </Form.Select>
    </div>
  );
};

export { MultiSelectDisplay, MultiSelectEdit };
