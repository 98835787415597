import React from 'react';
// this component keeps the user on the splashscreen until keycloak is ready
const Splashscreen = ({ keycloakReady, children }) => {
  if (!keycloakReady) {
    return <div>Loading...</div>;
  }

  return <>{children}</>;
};

export default Splashscreen;
