//create a pinned document item component
import React from 'react';
import { ListGroup } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
const PinnedDocumentItem = ({ pinnedDocument }) => {
  const navigate = useNavigate();

  //create a setHash function that lads the locationID to the URL
  const handleClick = (docID) => {
    //window.location.hash = `#view:${docID}`;
    navigate(`/pinned/${docID}`);
  };

  return (
    <ListGroup.Item action onClick={(e) => handleClick(pinnedDocument._id)}>
      <div className="d-flex w-100 justify-content-between align-content-start">
        <div className="h5 w-100 documentName text-danger">{pinnedDocument.name}</div>
        <div className="d-flex flex-column align-items-end pt-3">
          <i className="fa-sharp fa-solid fa-file-pdf fa-xl" />
        </div>
      </div>
      <div className="w-100">
        <small>{pinnedDocument.description}</small>
      </div>
    </ListGroup.Item>
  );
};
//export the component
export default PinnedDocumentItem;
