// PaginationComponent.jsx
import React from 'react';
import { Pagination, DropdownButton, Dropdown } from 'react-bootstrap';

const PaginationComponent = ({
  currentPage,
  totalPages,
  onPageChange,
  previousPage,
  nextPage,
  pageSize,
  totalEntries,
  onPageSizeChange, // Add this prop to handle page size change
}) => {
  const getPaginationButtons = (currentPage, totalPages) => {
    const buttons = [];
    const maxButtons = 7;

    buttons.push({ type: 'prev', page: currentPage - 1, disabled: currentPage === 1 });

    if (totalPages <= maxButtons) {
      for (let i = 1; i <= totalPages; i++) {
        buttons.push({ type: 'page', page: i, active: i === currentPage });
      }
    } else {
      buttons.push({ type: 'page', page: 1, active: 1 === currentPage });

      if (currentPage > 3) {
        buttons.push({ type: 'ellipsis' });
      }

      let start = Math.max(2, currentPage - 1);
      let end = Math.min(totalPages - 1, currentPage + 1);

      if (start === 2) end = 4;
      if (end === totalPages - 1) start = totalPages - 3;

      for (let i = start; i <= end; i++) {
        buttons.push({ type: 'page', page: i, active: i === currentPage });
      }

      if (currentPage < totalPages - 2) {
        buttons.push({ type: 'ellipsis' });
      }

      buttons.push({ type: 'page', page: totalPages, active: totalPages === currentPage });
    }

    buttons.push({ type: 'next', page: currentPage + 1, disabled: currentPage === totalPages });

    return buttons;
  };

  const paginationButtons = getPaginationButtons(currentPage, totalPages);
  const startEntry = (currentPage - 1) * pageSize + 1;
  const endEntry = Math.min(currentPage * pageSize, totalEntries);

  return (
    <div className="d-flex justify-content-center justify-content-sm-between align-items-center p-2 bg-white">
      <div className="d-flex align-items-center">
        <span className="d-none d-sm-block">
          Showing {startEntry} to {endEntry} of {totalEntries} entries
        </span>
        <DropdownButton title={pageSize} variant="secondary" drop="up" onSelect={(e) => onPageSizeChange(Number(e))} className="ms-2">
          <Dropdown.Item eventKey={5}>5</Dropdown.Item>
          <Dropdown.Item eventKey={10}>10</Dropdown.Item>
          <Dropdown.Item eventKey={20}>20</Dropdown.Item>
          <Dropdown.Item eventKey={50}>50</Dropdown.Item>
          <Dropdown.Item eventKey={100}>100</Dropdown.Item>
        </DropdownButton>
        <span className="ms-2 d-none d-sm-block">rows per page</span>
      </div>
      <div className="d-flex align-items-center">
        <Pagination className="ms-3 mb-0">
          {paginationButtons.map((button, index) => {
            if (button.type === 'prev') {
              return <Pagination.Prev key={index} onClick={previousPage} disabled={button.disabled} />;
            } else if (button.type === 'next') {
              return <Pagination.Next key={index} onClick={nextPage} disabled={button.disabled} />;
            } else if (button.type === 'ellipsis') {
              return <Pagination.Ellipsis key={index} disabled />;
            } else {
              return (
                <Pagination.Item key={index} active={button.active} onClick={() => onPageChange(button.page - 1)}>
                  {button.page}
                </Pagination.Item>
              );
            }
          })}
        </Pagination>
      </div>
    </div>
  );
};

export default PaginationComponent;
