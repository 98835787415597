import React, { useContext } from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { PortalContext } from '../../../context/Portal';

const NavigationBar = () => {
  const portalConfig = useContext(PortalContext);
  const logoRelativeUrl = portalConfig.portal.dashboardLogo;

  // Function to get the correct logo URL based on the environment
  const getLogoUrl = () => {
    if (process.env.NODE_ENV === 'production') {
      return logoRelativeUrl;
    } else {
      const apiBaseUrl = process.env.REACT_APP_API_BASE_URL || 'http://api.dss2.center';
      const subdomain = window.location.hostname.split('.')[0];
      return `${apiBaseUrl}/resources/${subdomain}/${logoRelativeUrl}`;
    }
  };

  const logoUrl = getLogoUrl();

  // Function to check if the file is a JPG or JPEG
  const isJpg = (url) => {
    return url.toLowerCase().endsWith('.jpg') || url.toLowerCase().endsWith('.jpeg');
  };

  return (
    <Navbar bg="dark" variant="dark" expand="lg" fixed="top">
      <Container fluid>
        <Navbar.Brand href="#home">
          {logoUrl && (
            <div className="logo-container" style={{ position: 'relative', display: 'inline-block' }}>
              <img
                src={logoUrl}
                alt="Dashboard Logo"
                className="d-inline-block align-top me-2"
                style={{ filter: isJpg(logoUrl) ? 'none' : 'brightness(0) invert(1)', maxHeight: '30px' }}
              />
            </div>
          )}
          <strong>SDS Management</strong>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="#home">Home</Nav.Link>
            <Nav.Link href="#chemicals">Chemicals</Nav.Link>
            <Nav.Link href="#reports">Reports</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavigationBar;
