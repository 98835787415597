import React, { useState, useEffect } from 'react';
import { Container, Button } from 'react-bootstrap';
import { Routes, Route, Navigate } from 'react-router-dom';
import NavigationBar from './sub-components/NavigationBar';
import LifecycleBar from './sub-components/LifecycleBar';
import PendingTable from './tables/PendingTable';
import InProcessTable from './tables/InProcessTable';
import ActiveTable from './tables/ActiveTable';
import ArchivedTable from './tables/ArchivedTable';
import { useGet } from '../../hooks/useGet';
import '../../Admin.css';

const Admin = () => {
  const [showLabelMaker, setShowLabelMaker] = useState(false);
  const [pendingRecords, setPendingRecords] = useState([]);
  const [inProcessRecords, setInProcessRecords] = useState([]);
  const [activeRecords, setActiveRecords] = useState([]);
  const [archivedRecords, setArchivedRecords] = useState([]);

  const { getData, data, isLoading, error } = useGet();

  useEffect(() => {
    getData('/admin/records');
  }, []);

  useEffect(() => {
    if (data.length > 0) {
      setPendingRecords(data.filter((record) => record.status === 'new'));
      setInProcessRecords(data.filter((record) => record.status === 'pending'));
      setActiveRecords(data.filter((record) => record.status === 'active'));
      setArchivedRecords(data.filter((record) => record.status === 'Archived'));
    }
  }, [data]);

  return (
    <Container fluid className="p-0" style={{ backgroundColor: 'white', minHeight: '100vh' }}>
      <NavigationBar />
      <LifecycleBar />

      <div id="tableArea" className="main-content mt-2">
        {isLoading ? (
          <p>Loading...</p>
        ) : error ? (
          <p>Error: {error.message}</p>
        ) : (
          <Routes>
            <Route path="pending" element={<PendingTable data={pendingRecords} />} />
            <Route path="in-process" element={<InProcessTable data={inProcessRecords} />} />
            <Route path="active" element={<ActiveTable data={activeRecords} />} />
            <Route path="archived" element={<ArchivedTable data={archivedRecords} />} />
            <Route path="*" element={<Navigate to="active" replace />} />
          </Routes>
        )}
      </div>

      {/* Label Maker Button */}
      <Button
        variant="primary"
        className="label-maker-btn"
        onClick={() => setShowLabelMaker(!showLabelMaker)}
        style={{
          position: 'fixed',
          right: 0,
          top: '50%',
          transform: 'rotate(-90deg) translateY(-50%)',
          transformOrigin: 'right bottom',
        }}
      >
        Label Maker
      </Button>

      {/* Label Maker Panel */}
      {showLabelMaker && (
        <div
          className="label-maker-panel"
          style={{
            position: 'fixed',
            right: 0,
            top: 0,
            bottom: 0,
            width: '300px',
            backgroundColor: '#f8f9fa',
            padding: '20px',
            boxShadow: '-2px 0 5px rgba(0,0,0,0.1)',
          }}
        >
          <h4>Label Maker</h4>
          {/* Add label maker content here */}
        </div>
      )}
    </Container>
  );
};

export default Admin;
